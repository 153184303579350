import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Components
import InputTable from '../../../components/input-table';
import LiabilityRow from '../../../components/liability-row';
import Content, { HTMLContent } from "../../../components/content-renderer";

const Liabilities = ({
	definitions,
}) => {
	const PageContent = HTMLContent || Content;

	// React Redux hooks
	const dispatch = useDispatch();
	const query = useSelector((state) => state.uccStepTracker);

	// Blank Liability Object
	const blankLiability =  {
		liabilityName: '',
		liabilityValue: '',
	};

	// Add a new blank liability object to liabilityState array
	const addLiability = () => {
		dispatch({
			type: 'SET_LIABILITY_STATE',
			payload: [...query.liabilityState, {...blankLiability}]
		});
	};

	// Remove liability row
	const handleRemoveLiabilityRow = (idx) => {
		const updatedLiability = [...query.liabilityState];

		updatedLiability.splice(idx, 1);
		
		dispatch({
			type: 'SET_LIABILITY_STATE',
			payload: updatedLiability
		});
	};

	//  Handle liability row change
	const handleLiabilityChange = (event) => {
		const updatedLiability = [...query.liabilityState];

		updatedLiability[event.target.dataset.idx][event.target.dataset.name] = event.target.value;

		dispatch({
			type: 'SET_LIABILITY_STATE',
			payload: updatedLiability
		});
	};

	return (
		<InputTable
			heading='Liabilities'
			buttonText='Add a liability'
			onClick={(event) => {
				event.preventDefault();
				addLiability();
			}}
			modalHeading={definitions[5].frontmatter.title}
          	modalContent={<PageContent content={definitions[5].html} />}
		>
			{query.liabilityState.map((item, index) => (
				<LiabilityRow
					key={`liability-${index}`}
					index={index}
					liabilityNameValue={query.liabilityState[index].liabilityName}
					liabilityValueValue={query.liabilityState[index].liabilityValue}
					onChange={handleLiabilityChange}
					handleRemoveRow={(event) => {
						event.preventDefault();
						handleRemoveLiabilityRow(index);
					}}
				/>
			))}
		</InputTable>
	);
};

Liabilities.propTypes = {
	definitions: PropTypes.array.isRequired,
};

export default Liabilities;