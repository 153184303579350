import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Components
import InputSwitch from '../../../components/input-switch';
import Content, { HTMLContent } from "../../../components/content-renderer";

const CapabilityToWork = ({
  definitions,
}) => {
  const PageContent = HTMLContent || Content;

  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.uccStepTracker);

  const updateLimitedCapability = () => {
    dispatch({
      type: 'SET_LIMITED_CAPABILITY',
      payload: !query.limitedCapability
    });
  };

  const updateLimitedCapabilityActivity = () => {
    dispatch({
      type: 'SET_LIMITED_CAPABILITY_ACTIVITY',
      payload: !query.limitedCapabilityActivity
    });
  };

  return (
    <>
      <div className='step-tracker__row'>
        <InputSwitch
          id="limited-capability"
          onChange={updateLimitedCapability}
          checked={query.limitedCapability}
          modalHeading={definitions[7].frontmatter.title}
          modalContent={<PageContent content={definitions[7].html} />}
        >
          Do you {(query.circumstance === 'coupleUnder' || query.circumstance === 'coupleOver') ? 'or your partner ' : ''}have a 'Limited capability to work'?
        </InputSwitch>
      </div>

      <div className='step-tracker__row'>
        <InputSwitch
          id="limited-capability-activity"
          onChange={updateLimitedCapabilityActivity}
          checked={query.limitedCapabilityActivity}
          modalHeading={definitions[6].frontmatter.title}
          modalContent={<PageContent content={definitions[6].html} />}
        >
          Do you {(query.circumstance === 'coupleUnder' || query.circumstance === 'coupleOver') ? 'or your partner ' : ''}have a 'Limited capability for work and work-related activity'?
        </InputSwitch>
      </div>
    </>
  );
};

CapabilityToWork.propTypes = {
  definitions: PropTypes.array.isRequired,
};

export default CapabilityToWork;