import React from 'react';
import { withPrefix, graphql } from 'gatsby';

// Layouts
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';
import UniversalCreditCalculator from '../../layouts/universal-credit-calculator';

// Components
import PageHeader from '../../components/page-header';
import Section from '../../components/section';
import Content, { HTMLContent } from "../../components/content-renderer";

const UniversalCredit = (props) => {
    const PageContent = HTMLContent || Content;

	const pageInfo = props.data.toolInfo;
    const definitions = props.data.definitions.nodes;
    const headerImage = pageInfo.frontmatter.image.relativePath;

	return (
		<Default>
			<Seo
				title={pageInfo.frontmatter.title}
				description={pageInfo.frontmatter.description}
			/>

			<PageHeader
				heading={pageInfo.frontmatter.title}
				imageSource={`${withPrefix("/")}img/${headerImage}`}
				modalContent={(
					<PageContent content={pageInfo.body} />
				)}
				description={pageInfo.frontmatter.description}
                breadcrumbs={[
                    {name: 'Home', url: '/'},
                    {name: 'Benefits', url: '/benefits'},
                ]}
			/>

            <Section
				label='Step tracker calculator'
				variants={['padded', 'secondary', 'secondary-first']}
			>
                <UniversalCreditCalculator definitions={definitions} />
            </Section>
            
		</Default>
	);
};

export default UniversalCredit;

export const UniversalCreditQuery = graphql`
    query UniversalCreditQuery($id: String!) {
        toolInfo: markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                title
                image {
                    relativePath
                }
                description
            }
        }
        definitions: allMarkdownRemark(
            filter: {fileAbsolutePath: {
                regex: "/src/definitions/applying-as-an-individual-couple|src/definitions/paid-work|src/definitions/studying-training-full-time|src/definitions/dependant-children|src/definitions/childcare|src/definitions/limited-capability-to-work|src/definitions/limited-capability-for-work-and-work-related-activity|src/definitions/monthly-salary-after-tax|src/definitions/student-loan-per-month|src/definitions/owned-property|src/definitions/savings|src/definitions/investments|src/definitions/assets|src/definitions/other-benefits|src/definitions/liabilities/"
            }},
            sort: {order: ASC, fields: frontmatter___title}
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
            }
        }
    }
`;