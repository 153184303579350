import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Components
import InputTable from '../../../components/input-table';
import InputTableRow from '../../../components/input-table/row';
import InputField from '../../../components/input-field';
import AssetRow from '../../../components/asset-row';
import Content, { HTMLContent } from "../../../components/content-renderer";

const Capital = ({
  definitions,
}) => {
  const PageContent = HTMLContent || Content;

  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.uccStepTracker);

  const updateSavings = (event) => {
    dispatch({
      type: 'SET_SAVINGS',
      payload: event.target.value === '' ? '' : parseFloat(event.target.value)
    });
  };

  const updateInvestments = (event) => {
    dispatch({
      type: 'SET_INVESTMENTS',
      payload: event.target.value === '' ? '' : parseFloat(event.target.value)
    });
  };

  const updateOwnedProperty = (event) => {
    dispatch({
      type: 'SET_OWNED_PROPERTY',
      payload: event.target.value === '' ? '' : parseFloat(event.target.value)
    });
  };

  // Blank Asset Object
	const blankAsset =  {
		assetName: '',
		assetValue: '',
  };
  
  // Add a new blank asset object to assetState array
	const addAsset = () => {
    dispatch({
      type: 'SET_ASSET_STATE',
      payload: [...query.assetState, {...blankAsset}]
    });
	};

	// Remove asset row
	const handleRemoveAssetRow = (idx) => {
		const updatedAssets = [...query.assetState];

		updatedAssets.splice(idx, 1);
    
    dispatch({
      type: 'SET_ASSET_STATE',
      payload: updatedAssets
    });
	};

	//  Handle asset row change
	const handleAssetChange = (event) => {
		const updatedAssets = [...query.assetState];

    updatedAssets[event.target.dataset.idx][event.target.dataset.name] = event.target.value;
    
    dispatch({
      type: 'SET_ASSET_STATE',
      payload: updatedAssets
    });
  };
  
  return (
    <>
      <InputTable heading='Your assets'>
        <InputTableRow>
          <InputField
            id='savings'
            labelText='Savings'
            modalHeading={definitions[12].frontmatter.title}
            modalContent={<PageContent content={definitions[12].html} />}
            placeholder='0'
            symbol='currency'
            min={0}
            onChange={updateSavings}
            value={query.savings}
          />

          <InputField
            id='investments'
            labelText='Investments'
            modalHeading={definitions[4].frontmatter.title}
            modalContent={<PageContent content={definitions[4].html} />}
            placeholder='0'
            symbol='currency'
            min={0}
            onChange={updateInvestments}
            value={query.investments}
          />

          <InputField
            id='owned-property'
            labelText='Property'
            modalHeading={definitions[10].frontmatter.title}
            modalContent={<PageContent content={definitions[10].html} />}
            placeholder="0"
            symbol='currency'
            min={0}
            onChange={updateOwnedProperty}
            value={query.ownedProperty}
          />
        </InputTableRow>
      </InputTable>

      <InputTable
        heading='Other assets'
        buttonText='Add an asset'
        onClick={(event) => {
          event.preventDefault();
          addAsset();
        }}
        modalHeading={definitions[1].frontmatter.title}
        modalContent={<PageContent content={definitions[1].html} />}
      >
        {query.assetState.map((item, index) => (
          <AssetRow
            key={`asset-${index}`}
            index={index}
            assetNameValue={query.assetState[index].assetName}
            assetValueValue={query.assetState[index].assetValue}
            onChange={handleAssetChange}
            handleRemoveRow={(event) => {
              event.preventDefault();
              handleRemoveAssetRow(index);
            }}
          />
        ))}
      </InputTable>
    </>
  );
};

Capital.propTypes = {
  definitions: PropTypes.array.isRequired,
};

export default Capital;