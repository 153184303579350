/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Helpers
import { formatNumber } from '../../../helpers/helpers';

// Component Imports
import Table from '../../../components/table';
import Toast from '../../../components/toast';

const Result = ({
    standardAmount,
    childAmount,
    disabledChildAmount,
    childcareAmount,
    carersAmount,
    limitedCapabilityAmount,
    workingHelpAmount,
    incomeDeduction,
    totalOtherBenefitsAmount,
    liabilityDeduction,
    totalMonthlyUniversalCredit,
    totalCapital,
    salary,
    partnerSalary,
    additionalIncomeValue,
    monthlyIncome,
    capitalTooHigh,
    incomeTooHigh,
}) => {
    // React Redux hooks
    const data = useSelector(state => state.uccStepTracker);
    const figures = useSelector(state => state.uccFigures);

    return (
        <>
            {capitalTooHigh && (
                <Toast heading='Your total capital is too high'>
                    <p>Your total capital is worth more than £16,000 and you will not be eligible for Universal Credit. If you are making a claim as a couple both of your assets will count towards your total capital.</p>
                    <p>Your total capital is worth: <strong>{formatNumber(totalCapital)}</strong></p>
                </Toast>
            )}

            {incomeTooHigh && (
                <Toast heading='Your household income is too high'>
                    <p>Universal Credit payments are reduced based on your household's monthly income. Your total monthly income is high enough that you will receive no Universal Credit payment per month.</p>
                    <p>Your total household income: <strong>{formatNumber(salary + partnerSalary + additionalIncomeValue + monthlyIncome)}</strong></p>
                </Toast>
            )}

            {!capitalTooHigh && !incomeTooHigh && (
                <Table>
                    <Table.Header>
                        <th></th>
                        <th>Amount (per month)</th>
                    </Table.Header>
                    <tbody>
                        <tr>
                            <td>Standard allowance</td>
                            <td>{formatNumber(standardAmount)}</td>
                        </tr>
                        {data.dependantChildren && (
                            <tr>
                                <td>Child amount</td>
                                <td>{formatNumber(childAmount)}</td>
                            </tr>
                        )}
                        {disabledChildAmount !== 0 && (
                            <tr>
                                <td>Disabled child amount</td>
                                <td>{formatNumber(disabledChildAmount)}</td>
                            </tr>
                        )}
                        {childcareAmount !== 0 && (
                            <tr>
                                <td>Childcare amount</td>
                                <td>{formatNumber(childcareAmount)}</td>
                            </tr>
                        )}
                        {(carersAmount !== 0 || limitedCapabilityAmount !== 0) && (
                            <tr>
                                <td>
                                    {
                                        (carersAmount !== 0 && limitedCapabilityAmount === 0) ? "Carer's amount" :
                                        (carersAmount === 0 && limitedCapabilityAmount !== 0) && (data.limitedCapability && !data.limitedCapabilityActivity) ? 'Limited capability for work amount' :
                                        (carersAmount === 0 && limitedCapabilityAmount !== 0) && (data.limitedCapabilityActivity && !data.limitedCapability) ? 'Limited capability for work and work-related activity amount' :
                                        (carersAmount === 0 && limitedCapabilityAmount !== 0) && (data.limitedCapability && data.limitedCapabilityActivity) && (figures.limitedCapability < figures.limitedCapabilityActivity) ? 'Limited capability for work and work-related activity amount' :
                                        (carersAmount === 0 && limitedCapabilityAmount !== 0) && (data.limitedCapability && data.limitedCapabilityActivity) && (figures.limitedCapability > figures.limitedCapabilityActivity) ? 'Limited capability for work amount' :
                                        (carersAmount !== 0 && limitedCapabilityAmount !== 0) && (carersAmount < limitedCapabilityAmount) && (data.limitedCapability && !data.limitedCapabilityActivity) ? 'Limited capability for work amount' :
                                        (carersAmount !== 0 && limitedCapabilityAmount !== 0) && (carersAmount < limitedCapabilityAmount) && (data.limitedCapabilityActivity && !data.limitedCapability)? 'Limited capability for work and work-related activity amount' :
                                        (carersAmount !== 0 && limitedCapabilityAmount !== 0) && (carersAmount < limitedCapabilityAmount) && (data.limitedCapability && data.limitedCapabilityActivity) && (figures.limitedCapability < figures.limitedCapabilityActivity) ? 'Limited capability for work and work-related activity amount' :
                                        (carersAmount !== 0 && limitedCapabilityAmount !== 0) && (carersAmount < limitedCapabilityAmount) && (data.limitedCapability && data.limitedCapabilityActivity) && (figures.limitedCapability > figures.limitedCapabilityActivity) ? 'Limited capability for work amount' :
                                        (carersAmount !== 0 && limitedCapabilityAmount !== 0) && (carersAmount > limitedCapabilityAmount) ? "Carer's amount" :
                                        ''
                                    }
                                </td>
                                <td>{formatNumber(workingHelpAmount)}</td>
                            </tr>
                        )}
                        {/* <tr>
                            <td>Housing costs</td>
                            <td>£0.00</td>
                        </tr> */}
                        {incomeDeduction !== 0 && (
                            <tr>
                                <td>Income deduction</td>
                                <td>-{formatNumber(incomeDeduction)}</td>
                            </tr>
                        )}
                        {totalOtherBenefitsAmount !== 0 && (
                            <tr>
                                <td>Other benefits</td>
                                <td>-{formatNumber(totalOtherBenefitsAmount)}</td>
                            </tr>
                        )}
                        {liabilityDeduction !== 0 && (
                            <tr>
                                <td>Liabilities deduction</td>
                                <td>-{formatNumber(liabilityDeduction)}</td>
                            </tr>
                        )}
                    </tbody>
                    
                    <Table.Footer>
                        <tr>
                            <td>Universal Credit payment</td>
                            <td>{formatNumber(totalMonthlyUniversalCredit)}</td>
                        </tr>
                    </Table.Footer>
                </Table>
            )}
        </>
    );
};

Result.propTypes = {
    standardAmount: PropTypes.number.isRequired,
    childAmount: PropTypes.number.isRequired,
    disabledChildAmount: PropTypes.number.isRequired,
    childcareAmount: PropTypes.number.isRequired,
    carersAmount: PropTypes.number.isRequired,
    limitedCapabilityAmount: PropTypes.number.isRequired,
    workingHelpAmount: PropTypes.number.isRequired,
    incomeDeduction: PropTypes.number.isRequired,
    totalOtherBenefitsAmount: PropTypes.number.isRequired,
    liabilityDeduction: PropTypes.number.isRequired,
    totalMonthlyUniversalCredit: PropTypes.number.isRequired,
    totalCapital: PropTypes.number.isRequired,
    salary: PropTypes.number.isRequired,
    partnerSalary: PropTypes.number.isRequired,
    additionalIncomeValue: PropTypes.number.isRequired,
    monthlyIncome: PropTypes.number.isRequired,
    capitalTooHigh: PropTypes.bool.isRequired,
    incomeTooHigh: PropTypes.bool.isRequired,
};

export default Result;