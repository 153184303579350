import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Components
import InputTable from '../../../components/input-table';
import InputTableRow from '../../../components/input-table/row';
import InputSwitch from '../../../components/input-switch';
import Select from '../../../components/select';
import InputField from '../../../components/input-field';
import Content, { HTMLContent } from "../../../components/content-renderer";

const Childcare = ({
  definitions,
}) => {
  const PageContent = HTMLContent || Content;

  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.uccStepTracker);

  const updateDependantChildren = () => {
    dispatch({
      type: 'SET_CHILDCARE',
      payload: !query.childcare
    });
  };

  // Blank Childcare Object
	const blankChildcare = {
		childcareCost: '',
		childcarePer: 'Year',
	};

  // Add blank childcare object to childState array
	const addChildcare = () => {
    dispatch({
      type: 'SET_CHILDCARE_STATE',
      payload: [...query.childcareState, {...blankChildcare}]
    });
	};
	
	// Remove childcare row
	const handleRemoveChildcareRow = (idx) => {
		const updatedChildcare = [...query.childcareState];

		updatedChildcare.splice(idx, 1);
    
    dispatch({
      type: 'SET_CHILDCARE_STATE',
      payload: updatedChildcare
    });
	};

	// Handle childcare 'Cost' input change
	const handleChildcareCostChange = (event) => {
		const updatedChildcare = [...query.childcareState];

		updatedChildcare[event.target.dataset.idx][event.target.dataset.name] = parseFloat(event.target.value);

    dispatch({
      type: 'SET_CHILDCARE_STATE',
      payload: updatedChildcare
    });
	};

	// Handle childcare 'Per' input change
	const handleChildcarePerChange = (idx, event) => {
		const updatedChildcare = [...query.childcareState];

		updatedChildcare[idx].childcarePer = event.target.value;

		dispatch({
      type: 'SET_CHILDCARE_STATE',
      payload: updatedChildcare
    });
  };
  
  return (
    <>
      <div className='step-tracker__row'>
        <InputSwitch
          id="childcare"
          onChange={updateDependantChildren}
          checked={query.childcare}
          modalHeading={definitions[2].frontmatter.title}
          modalContent={<PageContent content={definitions[2].html} />}
        >
          Do you pay for childcare?
        </InputSwitch>
      </div>

      {query.childcare && (
        <InputTable
          heading='Childcare costs'
          buttonText='Add more childcare'
          onClick={(event) => {
            event.preventDefault();
            addChildcare();
          }}
        >
          {query.childcareState.map((item, index) => (
            <InputTableRow
              heading={`${index+1}.`}
              handleRemove={(event) => {
                event.preventDefault();
                handleRemoveChildcareRow(index);
              }}
              key={index}
            >
              <InputField
                id={`childcare-cost-${index}`}
                required
                labelText='Childcare cost'
                symbol='currency'
                placeholder="0"
                min={0}
                dataIndex={index}
                dataName='childcareCost'
                value={isNaN(query.childcareState[index].childcareCost) ? 0 : query.childcareState[index].childcareCost}
                onChange={handleChildcareCostChange}
              />

              <Select
                labelText="Paid per"
                id={`childcare-per-${index}`}
                data-idx={index}
                data-name='childcarePer'
                onChange={(event) => handleChildcarePerChange(index, event)}
                required
                value={query.childcareState[index].childcarePer}
              >
                <Select.Option value="Year">Year</Select.Option>
                <Select.Option value="Month">Month</Select.Option>
                <Select.Option value="Week">Week</Select.Option>
                <Select.Option value="Day">Day</Select.Option>
              </Select>
            </InputTableRow>
          ))}
        </InputTable>
      )}
    </>
  );
};

Childcare.propTypes = {
  definitions: PropTypes.array.isRequired,
};

export default Childcare;