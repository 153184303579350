import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Components
import InputSwitch from '../../../components/input-switch';
import InputTable from '../../../components/input-table';
import InputTableRow from '../../../components/input-table/row';
import Select from '../../../components/select';
import Checkbox from '../../../components/checkbox';
import InputField from '../../../components/input-field';
import Content, { HTMLContent } from "../../../components/content-renderer";

const Children = ({
  definitions,
}) => {
  const PageContent = HTMLContent || Content;

  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.uccStepTracker);

  const updateDependantChildren = () => {
    dispatch({
      type: 'SET_DEPENDANT_CHILDREN',
      payload: !query.dependantChildren
    });
  };

  // Blank Child Object
	const blankChild = {
		childAge: '',
		childBornBefore: false,
		childDisabled: false,
		childDisabilityEntitlement: 'DLA1',
		childBlind: false,
  };
  
  // Add blank child object to childState array
  const addChild = () => {
    dispatch({
      type: 'SET_CHILD_STATE',
      payload: [...query.childState, {...blankChild}]
    });
  };
  
  // Remove child row
  const handleRemoveChildRow = (idx) => {
    // Make a copy of existing array
    const updatedChildren = [...query.childState];
    
    // Remove child row at clicked index
    updatedChildren.splice(idx, 1);
    
    // Replace array with newly spliced array
    dispatch({
      type: 'SET_CHILD_STATE',
      payload: updatedChildren
    });
  };
  
  // When a child row is updated
  const handleChildChange = (event) => {
    const updatedChildren = [...query.childState];
    
    // Use data-idx to locate the index of set of child inputs
    // Use data-name to find out which child value has updated in the object and set this value to the newly entered value
    updatedChildren[event.target.dataset.idx][event.target.dataset.name] = parseFloat(event.target.value);
    
    dispatch({
      type: 'SET_CHILD_STATE',
      payload: updatedChildren
    });
  };
  
  // Toggle child row 'Disabled' switch
  const handleChildDisabledChange = (idx) => {
    const updatedChildren = [...query.childState];
    
    updatedChildren[idx].childDisabled = !(updatedChildren[idx].childDisabled);
    
    dispatch({
      type: 'SET_CHILD_STATE',
      payload: updatedChildren
    });
  };
  
  // Handle child row 'Disability entitlement' select change
  const handleChildEntitlementChange = (idx, event) => {
    const updatedChildren = [...query.childState];
    
    updatedChildren[idx].childDisabilityEntitlement = event.target.value;
    
    dispatch({
      type: 'SET_CHILD_STATE',
      payload: updatedChildren
    });
  };
  
  // Toggle child row 'Blind' switch
  const handleChildBlindChange = (idx) => {
    const updatedChildren = [...query.childState];
    
    updatedChildren[idx].childBlind = !(updatedChildren[idx].childBlind);
    
    dispatch({
      type: 'SET_CHILD_STATE',
      payload: updatedChildren
    });
  };
  
  // Toggle child row 'Born before' switch
  const handleChildBornBeforeChange = (idx) => {
    const updatedChildren = [...query.childState];
    
    updatedChildren[idx].childBornBefore = !(updatedChildren[idx].childBornBefore);
    
    dispatch({
      type: 'SET_CHILD_STATE',
      payload: updatedChildren
    });
  };

  return (
    <>
      <div className='step-tracker__row'>
        <InputSwitch
          id="dependant-children"
          onChange={updateDependantChildren}
          checked={query.dependantChildren}
          modalHeading={definitions[3].frontmatter.title}
          modalContent={<PageContent content={definitions[3].html} />}
        >
          Do you have any dependant children?
        </InputSwitch>
      </div>

      {query.dependantChildren && (
        <InputTable
          heading='Dependent children'
          buttonText='Add a child'
          onClick={(event) => {
            event.preventDefault();
            addChild();
          }}
        >
          {query.childState.map((item, index) => (
            <InputTableRow
              heading={`${index+1}.`}
              toggled={query.childState[index].childDisabled}
              toggleContent={(
                <>
                  <Select
                    labelText="Disability entitlement"
                    id={`child-disability-entitlement-${index}`}
                    data-idx={index}
                    data-name='childDisabilityEntitlement'
                    onChange={(event) => handleChildEntitlementChange(index, event)}
                    value={query.childState[index].childDisabilityEntitlement}
                  >
                    <Select.Option value="DLA1">DLA (Disability Living Allowance)</Select.Option>
                    <Select.Option value="DLA2">DLA Higher Rate (Disability Living Allowance)</Select.Option>
                    {(isNaN(query.childState[index].childAge) ? '' : query.childState[index].childAge >= 16 && isNaN(query.childState[index].childAge) ? '' : query.childState[index].childAge < 19) && (
                      <>
                        <Select.Option value="PIP1">PIP (Personal Independance Payment)</Select.Option>
                        <Select.Option value="PIP2">PIP Enhanced Rate (Personal Independance Payment)</Select.Option>
                      </>
                    )}
                  </Select>

                  <Checkbox
                    id={`child-blind-${index}`}
                    data-idx={index}
                    data-name='childBlind'
                    onChange={() => handleChildBlindChange(index)}
                    checked={query.childState[index].childBlind}
                  >
                    Legally Blind?
                  </Checkbox>
                </>
              )}
              handleRemove={(event) => {
                event.preventDefault();
                handleRemoveChildRow(index);
              }}
              key={index}
            >
              <InputField
                id={`child-age-${index}`}
                labelText='Age (years)'
                placeholder="0"
                min={0}
                dataIndex={index}
                dataName='childAge'
                value={isNaN(query.childState[index].childAge) ? '' : query.childState[index].childAge}
                onChange={handleChildChange}
              />

              <Checkbox
                id={`child-born-before-${index}`}
                data-idx={index}
                data-name='childBornBefore'
                onChange={() => handleChildBornBeforeChange(index)}
                checked={query.childState[index].childBornBefore}
              >
                Born before 6th April 2017?
              </Checkbox>

              <Checkbox
                id={`child-disabled-${index}`}
                data-idx={index}
                data-name='childDisabled'
                onChange={() => handleChildDisabledChange(index)}
                checked={query.childState[index].childDisabled}
              >
                Disabled?
              </Checkbox>
            </InputTableRow>
          ))}
        </InputTable>
      )}
    </>
  );
};

Children.propTypes = {
  definitions: PropTypes.array.isRequired,
};

export default Children;