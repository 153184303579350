/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Component Imports
import StepTracker from '../../components/step-tracker';

// Partial Imports
import AboutYou from './steps/about-you';
import Children from './steps/children';
import Childcare from './steps/childcare';
import CaringForOthers from './steps/caring-for-others';
import CapabilityToWork from './steps/capability-to-work';
// import Housing from './steps/Housing';
import Earnings from './steps/earnings';
import Capital from './steps/capital';
import OtherBenefits from './steps/other-benefits';
import Liabilities from './steps/liabilities';
import Results from './steps/results';

const UniversalCreditCalculator = ({
	definitions,
}) => {
  	// React Redux hooks
	const dispatch = useDispatch();
	const query = useSelector(state => state.uccStepTracker);
	const figures = useSelector(state => state.uccFigures);

	useEffect(() => {
        dispatch({type: 'RESET_UCC_SELECTIONS'});
    }, []);

	const calcMonthlyAmount = (value, paidPer) => {
		let amount;

		switch (paidPer) {
			case 'Year':
				amount = parseFloat(value) / 12
				break;
			case 'Month':
				amount = parseFloat(value)
				break;
			case 'Week':
				amount = parseFloat(value) * 4
				break;
			case 'Day':
				amount = parseFloat(value) * 30
				break;
			default:
		}

		return amount;
	};

	// Standard Universal Credit Amount
	const standardAmount = () => {
		let value = figures.singleUnder;

		switch (query.circumstance) {
			case 'singleUnder':
				value = figures.singleUnder
				break;
			case 'singleOver':
				value = figures.singleOver
				break;
			case 'coupleUnder':
				value = figures.coupleUnder
				break;
			case 'coupleOver':
				value = figures.coupleOver
				break;
			default:
		}

		return value;
	};

	// Child amount
	const checkChildBornBefore = () => {
		return query.childState.some(child => child.childBornBefore === true);
	};

	const calcChildAmount = () => {
		let value = 0;

		if (query.dependantChildren) {
			if (query.childState.length === 1) {
				if (checkChildBornBefore()) {
					value = figures.firstChildBefore;
				} else {
					value = figures.firstChildAfter;
				}
			}
			else if (query.childState.length > 1) {
				if (checkChildBornBefore()) {
					value = figures.firstChildBefore + figures.secondChild;
				} else {
					value = figures.firstChildAfter + figures.secondChild;
				}
			}
		}

		return value;
	};

	// Disabled child amount
	let numberDisabledChildrenLowerRate = 0;
	let numberDisabledChildrenHigherRate = 0;
	for (let i = 0; i < query.childState.length; i++) {
		if (query.childState[i].childDisabled && (query.childState[i].childDisabilityEntitlement === 'DLA1' || query.childState[i].childDisabilityEntitlement === 'PIP1') && !query.childState[i].childBlind) {
			numberDisabledChildrenLowerRate += 1;
		}
		if (query.childState[i].childDisabled && (query.childState[i].childDisabilityEntitlement === 'DLA1' || query.childState[i].childDisabilityEntitlement === 'PIP1') && query.childState[i].childBlind) {
			numberDisabledChildrenHigherRate += 1;
		}
		else if (query.childState[i].childDisabled && (query.childState[i].childDisabilityEntitlement === 'DLA2' || query.childState[i].childDisabilityEntitlement === 'PIP2') && !query.childState[i].childBlind) {
			numberDisabledChildrenHigherRate += 1;
		}
		else if (query.childState[i].childDisabled && (query.childState[i].childDisabilityEntitlement === 'DLA2' || query.childState[i].childDisabilityEntitlement === 'PIP2') && query.childState[i].childBlind) {
			numberDisabledChildrenHigherRate += 1;
		}
	}

	const calcDisabledChildAmount = () => {
		return query.dependantChildren ? (numberDisabledChildrenLowerRate * figures.disabledChildLowerRate) + (numberDisabledChildrenHigherRate * figures.disabledChildHigherRate) : 0;
	};

	// Total Childcare UC Amount
	let totalChildcareCost = 0;
	for (let i = 0; i < query.childcareState.length; i++) {
		(query.childcareState[i].childcareCost === '') || isNaN(query.childcareState[i].childcareCost) ? totalChildcareCost += 0 :
			totalChildcareCost += calcMonthlyAmount(query.childcareState[i].childcareCost, query.childcareState[i].childcarePer);
	}
	
	const calcChildcareAmount = query.childcare && (query.paidWork || query.partnerPaidWork) && (query.childcareState.length === 1 && totalChildcareCost <= figures.childcareMaxSingle) ? totalChildcareCost :
		query.childcare && (query.paidWork || query.partnerPaidWork) && (query.childcareState.length === 1 && totalChildcareCost > figures.childcareMaxSingle) ? figures.childcareMaxSingle :
		query.childcare && (query.paidWork || query.partnerPaidWork) && (query.childcareState.length > 1 && totalChildcareCost <= figures.childcareMaxMultiple) ? totalChildcareCost :
		query.childcare && (query.paidWork || query.partnerPaidWork) && (query.childcareState.length > 1 && totalChildcareCost > figures.childcareMaxMultiple) ? figures.childcareMaxMultiple :
		0;

	// Caring for others
	const calcCarersAmount = () => {
		let value = 0;

		if (query.caringForOthers) {

			if ((query.careAmount && !query.partnerCareAmount) || (query.partnerCareAmount && !query.careAmount) || (query.careAmount && query.partnerCareAmount && query.careSamePerson)) {
				value = figures.carersAmount;
			}
			else if (query.careAmount && query.partnerCareAmount && !query.careSamePerson) {
				value = figures.carersAmount * 2;
			}
		}

		return value;
	};

	// Capability to work
	const calcLimitedCapabilityAmount = query.limitedCapability && !query.limitedCapabilityActivity ? figures.limitedCapability :
		query.limitedCapabilityActivity && !query.limitedCapability ? figures.limitedCapabilityActivity :
		(query.limitedCapability && query.limitedCapabilityActivity) && (figures.limitedCapability < figures.limitedCapabilityActivity) ? figures.limitedCapabilityActivity :
		(query.limitedCapability && query.limitedCapabilityActivity) && (figures.limitedCapability > figures.limitedCapabilityActivity) ? figures.limitedCapability :
		0;

	// Caring for others OR Capability to work
	const calcWorkingHelpAmount = (calcCarersAmount() !== 0 && calcLimitedCapabilityAmount === 0) ? calcCarersAmount() :
		(calcCarersAmount() === 0 && calcLimitedCapabilityAmount !== 0) ? calcLimitedCapabilityAmount :
		(calcCarersAmount() !== 0 && calcLimitedCapabilityAmount !== 0) && (calcCarersAmount() < calcLimitedCapabilityAmount) ? calcLimitedCapabilityAmount :
		(calcCarersAmount() !== 0 && calcLimitedCapabilityAmount !== 0) && (calcCarersAmount() > calcLimitedCapabilityAmount) ? calcCarersAmount() :
		0;

	// Earnings & other income
	let totalAdditionalIncomeValue = 0;
	for (let i = 0; i < query.incomeState.length; i++) {
		(query.incomeState[i].incomeValue === '') || isNaN(query.incomeState[i].incomeValue) ? totalAdditionalIncomeValue += 0 :
			totalAdditionalIncomeValue += parseFloat(query.incomeState[i].incomeValue);
	}
	const calcSalary = query.salary ? parseFloat(query.salary) : 0;
	const calcPartnerSalary = query.partnerSalary ? parseFloat(query.partnerSalary) : 0;

	// Student Loan
	const calcStudentLoan = query.studentLoan ? parseFloat(query.studentLoan) : 0;
	const calcPartnerStudentLoan = query.partnerStudentLoan ? parseFloat(query.partnerStudentLoan) : 0;
	
	// Total Capital
	let totalAssetValue = 0;
	for (let i = 0; i < query.assetState.length; i++) {
		(query.assetState[i].assetValue === '') || isNaN(query.assetState[i].assetValue) ? totalAssetValue += 0 :
			totalAssetValue += parseFloat(query.assetState[i].assetValue);
	}
	const calcTotalCapital = (query.savings === '' ? 0 : parseFloat(query.savings)) + (query.investments === '' ? 0 : parseFloat(query.investments)) + (query.ownedProperty === '' ? 0 : parseFloat(query.ownedProperty)) + totalAssetValue;
	
	const calcCapitalMonthlyIncome = calcTotalCapital <= figures.capitalAllowance ? 0 :
	(calcTotalCapital > figures.capitalAllowance && calcTotalCapital <= figures.capitalHigherRate) ? parseInt(((calcTotalCapital - figures.capitalAllowance) / figures.capitalIncrement), 10) * figures.capitalMonthlyIncome :
	calcTotalCapital > figures.capitalHigherRate ? 0 :
	0;

	// Work Allowance
	const calcWorkAllowance = (query.dependantChildren && query.childState.length > 0) || (query.limitedCapability || query.limitedCapabilityActivity) ? figures.workAllowanceHigher : 0;

	// Total monthly income
	const calcTotalMonthlyIncome = calcSalary + calcPartnerSalary + calcStudentLoan + calcPartnerStudentLoan + totalAdditionalIncomeValue + calcCapitalMonthlyIncome - calcWorkAllowance;

	const calcTotalMonthlyIncomeCorrected = calcTotalMonthlyIncome < 0 ? 0 : calcTotalMonthlyIncome;

	// Income deduction
	const calcIncomeDeduction = calcTotalMonthlyIncomeCorrected * figures.incomeDeductionRatio;

	// Total Other Benefits
	const calcTotalOtherBenefitsAmount = () => {
		let total = 0;
		let carersAllowance = 0;
		let incapacityBenefit = 0;
		let maternityAllowance = 0;
		let ESA = 0;
		let JSA = 0;

		if (query.benefits) {

			if (query.carersAllowance) {
				carersAllowance = query.carersAllowanceAmount ? query.carersAllowanceAmount : 0;
			}
			if (query.incapacityBenefit) {
				incapacityBenefit = query.incapacityBenefitAmount ? query.incapacityBenefitAmount : 0;
			}
			if (query.maternityAllowance) {
				maternityAllowance = query.maternityAllowanceAmount ? query.maternityAllowanceAmount : 0;
			}
			if (query.ESA) {
				ESA = query.ESAAmount ? query.ESAAmount : 0;
			}
			if (query.JSA) {
				JSA = query.JSAAmount ? query.JSAAmount : 0;
			}

			total = carersAllowance + incapacityBenefit + maternityAllowance + ESA + JSA;
		}
		return total;
	};

	// Total Liability Amount
	const calcLiabilityDeduction = () => {
		let totalValue = 0;
		let maxDeduction = standardAmount() * figures.liabilityMaxPercentage;
		let deduction = 0;

		for (let i = 0; i < query.liabilityState.length; i++) {
			(query.liabilityState[i].liabilityValue === '') || isNaN(query.liabilityState[i].liabilityValue) ? totalValue += 0 :
			totalValue += parseFloat(query.liabilityState[i].liabilityValue);
		}

		deduction = totalValue <= maxDeduction ? totalValue : maxDeduction;

		return deduction;
	};

	// Total Universal Credit Monthly Payment
	const totalMonthlyUniversalCredit = () => {
		return standardAmount() + calcChildAmount() + calcDisabledChildAmount() + calcChildcareAmount + calcWorkingHelpAmount - calcIncomeDeduction - calcTotalOtherBenefitsAmount() - calcLiabilityDeduction();
	};

	const capitalTooHigh = calcTotalCapital > figures.capitalHigherRate;
    const incomeTooHigh = standardAmount() + calcChildAmount() + calcDisabledChildAmount() + calcChildcareAmount + calcWorkingHelpAmount - calcTotalOtherBenefitsAmount() - calcLiabilityDeduction() <= calcIncomeDeduction;
	const resultString = capitalTooHigh || incomeTooHigh ? 'You are not eligible to receive Universal Credit payments' : `Your estimated monthly Universal Credit payment is: £${totalMonthlyUniversalCredit().toFixed(2)} - see your full personal breakdown below`;

	const steps = [
        {name: 'About you', heading: 'About you', description: "In order to calculate your potential Universal Credit payment we need to know a bit about you (and your partner if you're making a joint claim)", component: <AboutYou definitions={definitions} />},
        {name: 'Children', heading: 'Children', description: 'If you have any dependant children please list them below and fill out any relevant details', component: <Children definitions={definitions} />},
        {name: 'Childcare', heading: 'Childcare', description: 'If you pay any form of childcare please list these costs below', component: <Childcare definitions={definitions} />},
        {name: 'Care', heading: 'Caring for others', description: 'If you provide care for one or more people with a severe disability please select the appropriate options below', component: <CaringForOthers />},
        {name: 'Work', heading: 'Capability to work', description: 'If you have been assessed as having a limited capability to work please select the appropriate options below', component: <CapabilityToWork definitions={definitions} />},
		// {name: 'Housing', heading: 'Housing', description: 'Your residential situation can affect your Universal Credit entitlement - please select the appropriate options below', component: <Housing />},
		{name: 'Earnings', heading: 'Earnings', description: 'Please list any regular incomes and other earnings that you receive below', component: <Earnings definitions={definitions} />},
		{name: 'Capital', heading: 'Capital', description: 'Please list any substantial savings, investments, property or other assets that you own', component: <Capital definitions={definitions} />},
		{name: 'Benefits', heading: 'Other benefits', description: 'If you currently receive any other benefits payments please specify the amount you get paid per month below', component: <OtherBenefits definitions={definitions} />},
		{name: 'Liabilities', heading: 'Liabilities', description: 'If you have debts or any other outstanding payments that you are liable for please list these below', component: <Liabilities definitions={definitions} />},
		{
			name: 'Results',
			heading: 'Results',
			description: resultString,
			component: (
				<Results
					standardAmount={standardAmount()}
					childAmount={calcChildAmount()}
					disabledChildAmount={calcDisabledChildAmount()}
					childcareAmount={calcChildcareAmount}
					carersAmount={calcCarersAmount()}
					limitedCapabilityAmount={calcLimitedCapabilityAmount}
					workingHelpAmount={calcWorkingHelpAmount}
					incomeDeduction={calcIncomeDeduction}
					totalOtherBenefitsAmount={calcTotalOtherBenefitsAmount()}
					liabilityDeduction={calcLiabilityDeduction()}
					totalMonthlyUniversalCredit={totalMonthlyUniversalCredit()}
					totalCapital={calcTotalCapital}
					salary={calcSalary}
					partnerSalary={calcPartnerSalary}
					additionalIncomeValue={totalAdditionalIncomeValue}
					monthlyIncome={calcCapitalMonthlyIncome}
					capitalTooHigh={capitalTooHigh}
					incomeTooHigh={incomeTooHigh}
				/>
			)
		},
  	];
  
	return (
		<StepTracker
			steps={steps}
			storeName='uccStepTracker'
			data={query}
		/>
	);
};

UniversalCreditCalculator.propTypes = {
	definitions: PropTypes.array.isRequired,
};

export default UniversalCreditCalculator;