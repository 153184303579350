import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Component imports
import InputSwitch from '../../../components/input-switch';

const CaringForOthers = () => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.uccStepTracker);

  const updateCaringForOthers = () => {
    dispatch({
      type: 'SET_CARING_FOR_OTHERS',
      payload: !query.caringForOthers
    });
  };

  const updateCareAmount = () => {
    dispatch({
      type: 'SET_CARE_AMOUNT',
      payload: !query.careAmount
    });
  };

  const updatePartnerCareAmount = () => {
    dispatch({
      type: 'SET_PARTNER_CARE_AMOUNT',
      payload: !query.partnerCareAmount
    });
  };

  const updateCareSamePerson = () => {
    dispatch({
      type: 'SET_CARE_SAME_PERSON',
      payload: !query.careSamePerson
    });
  };

  return (
    <>
      <div className='step-tracker__row'>
        <InputSwitch
          id="caring-for-others"
          onChange={updateCaringForOthers}
          checked={query.caringForOthers}
        >
          Do you{(query.circumstance === 'coupleUnder' || query.circumstance === 'coupleOver') ? ' or your partner' : ''} provide care for anyone who is severely disabled?
        </InputSwitch>
      </div>

      {query.caringForOthers && (
        <>
          <div className='step-tracker__row'>
            <InputSwitch
              id="care-provision-amount"
              onChange={updateCareAmount}
              checked={query.careAmount}
              required
            >
              Do you provide care for 35 hours or more per week?
            </InputSwitch>
          </div>
          
          {(query.circumstance === 'coupleUnder' || query.circumstance === 'coupleOver') && (
            <>
            <div className='step-tracker__row'>
              <InputSwitch
                id="partner-care-provision-amount"
                onChange={updatePartnerCareAmount}
                checked={query.partnerCareAmount}
                required
              >
                Does your partner provide care for 35 hours or more per week?
              </InputSwitch>
            </div>

              {(query.careAmount && query.partnerCareAmount) && (
                <div className='step-tracker__row'>
                  <InputSwitch
                    id="partner-same-person"
                    onChange={updateCareSamePerson}
                    checked={query.careSamePerson}
                    required
                  >
                    Do you and your partner provide care to the same person?
                  </InputSwitch>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default CaringForOthers;