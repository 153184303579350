import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Select from '../../../components/select';
import InputSwitch from '../../../components/input-switch';
import Content, { HTMLContent } from "../../../components/content-renderer";

const AboutYou = ({
  definitions,
}) => {
  const PageContent = HTMLContent || Content;

  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.uccStepTracker);

  const updateCircumstance = (e) => {
    dispatch({
      type: 'SET_CIRCUMSTANCE',
      payload: e.target.value
    });
  };

  const updatePaidWork = () => {
    dispatch({
      type: 'SET_PAID_WORK',
      payload: !query.paidWork
    });
  };

  const updateEducation = () => {
    dispatch({
      type: 'SET_EDUCATION',
      payload: !query.education
    });
  };

  const updatePartnerPaidWork = () => {
    dispatch({
      type: 'SET_PARTNER_PAID_WORK',
      payload: !query.partnerPaidWork
    });
  };

  const updatePartnerEducation = () => {
    dispatch({
      type: 'SET_PARTNER_EDUCATION',
      payload: !query.partnerEducation
    });
  };

  return (
    <>
      <div className='step-tracker__row'>
        <Select
          labelText="Your circumstance"
          id="circumstance"
          onChange={updateCircumstance}
          required
          modalHeading={definitions[0].frontmatter.title}
          modalContent={<PageContent content={definitions[0].html} />}
          value={query.circumstance}
        >
          <Select.Option value="singleUnder">Single and under 25</Select.Option>
          <Select.Option value="singleOver">Single and 25 or over</Select.Option>
          <Select.Option value="coupleUnder">Couple and you’re both under 25</Select.Option>
          <Select.Option value="coupleOver">Couple and either of you are 25 or over</Select.Option>
        </Select>
      </div>

      <div className='step-tracker__row'>
        <InputSwitch
          id="paid-work"
          onChange={updatePaidWork}
          checked={query.paidWork}
          modalHeading={definitions[11].frontmatter.title}
          modalContent={<PageContent content={definitions[11].html} />}
        >
          Are you in paid work?
        </InputSwitch>
      </div>

      <div className='step-tracker__row'>
        <InputSwitch
          id="education"
          onChange={updateEducation}
          checked={query.education}
          modalHeading={definitions[14].frontmatter.title}
          modalContent={<PageContent content={definitions[14].html} />}
        >
          Are you studying/training full-time?
        </InputSwitch>
      </div>

      {(query.circumstance === 'coupleUnder' || query.circumstance === 'coupleOver') && (
        <>
          <div className='step-tracker__row'>
            <InputSwitch
              id="partner-paid-work"
              onChange={updatePartnerPaidWork}
              checked={query.partnerPaidWork}
            >
              Partner is in paid work
            </InputSwitch>
          </div>

          <div className='step-tracker__row'>
            <InputSwitch
              id="partner-education"
              onChange={updatePartnerEducation}
              checked={query.partnerEducation}
            >
              Partner is studying/training full-time
            </InputSwitch>
          </div>
        </>
      )}
    </>
  );
};

AboutYou.propTypes = {
  definitions: PropTypes.array.isRequired,
};

export default AboutYou;