import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Components
import InputField from '../../../components/input-field';
import InputSwitch from '../../../components/input-switch';
import InfoModal from '../../../components/info-modal';
import Content, { HTMLContent } from "../../../components/content-renderer";

const OtherBenefits = ({
  definitions,
}) => {
  const PageContent = HTMLContent || Content;
  
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.uccStepTracker);

  const updateBenefits = () => {
    dispatch({
      type: 'SET_BENEFITS',
      payload: !query.benefits
    });
  };

  const updateCarersAllowance = () => {
    dispatch({
      type: 'SET_CARERS_ALLOWANCE',
      payload: !query.carersAllowance
    });
  };

  const updateCarersAllowanceAmount = (event) => {
    dispatch({
      type: 'SET_CARERS_ALLOWANCE_AMOUNT',
      payload: event.target.value === '' ? '' : parseFloat(event.target.value)
    });
  };

  const updateIncapacityBenefit = () => {
    dispatch({
      type: 'SET_INCAPACITY_BENEFIT',
      payload: !query.incapacityBenefit
    });
  };

  const updateIncapacityBenefitAmount = (event) => {
    dispatch({
      type: 'SET_INCAPACITY_BENEFIT_AMOUNT',
      payload: event.target.value === '' ? '' : parseFloat(event.target.value)
    });
  };

  const updateMaternityAllowance = () => {
    dispatch({
      type: 'SET_MATERNITY_ALLOWANCE',
      payload: !query.maternityAllowance
    });
  };

  const updateMaternityAllowanceAmount = (event) => {
    dispatch({
      type: 'SET_MATERNITY_ALLOWANCE_AMOUNT',
      payload: event.target.value === '' ? '' : parseFloat(event.target.value)
    });
  };

  const updateESA = () => {
    dispatch({
      type: 'SET_ESA',
      payload: !query.ESA
    });
  };

  const updateESAAmount = (event) => {
    dispatch({
      type: 'SET_ESA_AMOUNT',
      payload: event.target.value === '' ? '' : parseFloat(event.target.value)
    });
  };

  const updateJSA = () => {
    dispatch({
      type: 'SET_JSA',
      payload: !query.JSA
    });
  };

  const updateJSAAmount = (event) => {
    dispatch({
      type: 'SET_JSA_AMOUNT',
      payload: event.target.value === '' ? '' : parseFloat(event.target.value)
    });
  };

  return (
    <>
      <div className='step-tracker__row'>
        <InputSwitch
          id="other-benefits"
          onChange={updateBenefits}
          checked={query.benefits}
        >
          Do you{(query.circumstance === 'coupleUnder' || query.circumstance === 'coupleOver') ? ' or your partner' : ''} receive any other benefits?
        </InputSwitch>
      </div>

      {query.benefits && (
        <>
          <div className='step-tracker__row'>
            <InputSwitch
              id="carers-allowance"
              onChange={updateCarersAllowance}
              checked={query.carersAllowance}
            >
              Carer’s Allowance
            </InputSwitch>
          </div>

          {query.carersAllowance && (
            <div className='step-tracker__row'>
              <InputField
                id='carers-allowance-amount'
                labelText="Carer's Allowance (per month)"
                placeholder='0'
                symbol='currency'
                min={0}
                onChange={updateCarersAllowanceAmount}
                value={query.carersAllowanceAmount}
              />
            </div>
          )}

          <div className='step-tracker__row'>
            <InputSwitch
              id="incapacity-benefit"
              onChange={updateIncapacityBenefit}
              checked={query.incapacityBenefit}
            >
              Incapacity Benefit
            </InputSwitch>
          </div>

          {query.incapacityBenefit && (
            <div className='step-tracker__row'>
              <InputField
                id='incapacity-benefit-amount'
                labelText="Incapacity Benefit (per month)"
                placeholder='0'
                symbol='currency'
                min={0}
                onChange={updateIncapacityBenefitAmount}
                value={query.incapacityBenefitAmount}
              />
            </div>
          )}

          <div className='step-tracker__row'>
            <InputSwitch
              id="maternity-allowance"
              onChange={updateMaternityAllowance}
              checked={query.maternityAllowance}
            >
              Maternity Allowance
            </InputSwitch>
          </div>

          {query.maternityAllowance && (
            <div className='step-tracker__row'>
              <InputField
                id='maternity-allowance-amount'
                labelText="Maternity Allowance (per month)"
                placeholder='0'
                symbol='currency'
                min={0}
                onChange={updateMaternityAllowanceAmount}
                value={query.maternityAllowanceAmount}
              />
            </div>
          )}

          <div className='step-tracker__row'>
            <InputSwitch
              id="new-style-esa"
              onChange={updateESA}
              checked={query.ESA}
            >
              ESA (New style Employment and Support Allowance)
            </InputSwitch>
          </div>

          {query.ESA && (
            <div className='step-tracker__row'>
              <InputField
                id='new-style-esa-amount'
                labelText="New style ESA (per month)"
                placeholder='0'
                symbol='currency'
                min={0}
                onChange={updateESAAmount}
                value={query.ESAAmount}
              />
            </div>
          )}

          <div className='step-tracker__row'>
            <InputSwitch
              id="new-style-jsa"
              onChange={updateJSA}
              checked={query.JSA}
            >
              JSA (New style Job-seekers Allowance)
            </InputSwitch>
          </div>

          {query.JSA && (
            <div className='step-tracker__row'>
              <InputField
                id='new-style-jsa-amount'
                labelText="New style JSA (per month)"
                placeholder='0'
                symbol='currency'
                min={0}
                onChange={updateJSAAmount}
                value={query.JSAAmount}
              />
            </div>
          )}

          <div className='step-tracker__row'>
            <InfoModal
              triggerText='Do you receive another benefit not listed above?'
              heading={definitions[9].frontmatter.title}
            >
              <PageContent content={definitions[9].html} />
            </InfoModal>
          </div>
        </>
      )}
    </>
  );
};

OtherBenefits.propTypes = {
  definitions: PropTypes.array.isRequired,
};

export default OtherBenefits;