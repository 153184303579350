import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Components
import InputTable from '../../../components/input-table';
import InputTableRow from '../../../components/input-table/row';
import InputField from '../../../components/input-field';
import Content, { HTMLContent } from "../../../components/content-renderer";

const Earnings = ({
  definitions,
}) => {
  const PageContent = HTMLContent || Content;

  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.uccStepTracker);
  
  const updateSalary = (event) => {
    dispatch({
      type: 'SET_SALARY',
      payload: event.target.value === '' ? '' : parseFloat(event.target.value)
    });
  };

  const updateStudentLoan = (event) => {
    dispatch({
      type: 'SET_STUDENT_LOAN',
      payload: event.target.value === '' ? '' : parseFloat(event.target.value)
    });
  };

  const updatePartnerSalary = (event) => {
    dispatch({
      type: 'SET_PARTNER_SALARY',
      payload: event.target.value === '' ? '' : parseFloat(event.target.value)
    });
  };

  const updatePartnerStudentLoan = (event) => {
    dispatch({
      type: 'SET_PARTNER_STUDENT_LOAN',
      payload: event.target.value === '' ? '' : parseFloat(event.target.value)
    });
  };

  // Blank Income Object
	const blankIncome = {
		incomeName: '',
		incomeValue: '',
	};

  // Add blank income object to incomeState array
	const addIncome = () => {
    dispatch({
      type: 'SET_INCOME_STATE',
      payload: [...query.incomeState, {...blankIncome}]
    });
	};

	// Remove income row
	const handleRemoveIncomeRow = (idx) => {
		const updatedIncomes = [...query.incomeState];

		updatedIncomes.splice(idx, 1);
    
		dispatch({
      type: 'SET_INCOME_STATE',
      payload: updatedIncomes
    });
	};

	//  Handle income row change
	const handleIncomeChange = (event) => {
		const updatedIncomes = [...query.incomeState];

		updatedIncomes[event.target.dataset.idx][event.target.dataset.name] = event.target.value;

    dispatch({
      type: 'SET_INCOME_STATE',
      payload: updatedIncomes
    });
	};

  return (
    <>
      {(query.paidWork || query.education) && (
        <InputTable heading='Your income'>
          <InputTableRow>
            {query.paidWork && (
              <InputField
                id='salary'
                required
                labelText='Monthly salary (after tax)'
                modalHeading={definitions[8].frontmatter.title}
                modalContent={<PageContent content={definitions[8].html} />}
                placeholder='0'
                symbol='currency'
                min={0}
                onChange={updateSalary}
                value={query.salary}
              />
            )}

            {query.education && (
              <InputField
                id='student-loan'
                required
                labelText='Student loan (per month)'
                modalHeading={definitions[13].frontmatter.title}
                modalContent={<PageContent content={definitions[13].html} />}
                placeholder='0'
                symbol='currency'
                min={0}
                onChange={updateStudentLoan}
                value={query.studentLoan}
              />
            )}
          </InputTableRow>
        </InputTable>
      )}

      {(query.circumstance === 'coupleUnder' || query.circumstance === 'coupleOver') && (query.partnerPaidWork || query.partnerEducation) && (
        <InputTable heading="Partner's income">
          <InputTableRow>
            {query.partnerPaidWork && (
              <InputField
                id='partner-salary'
                required
                labelText='Monthly salary (after tax)'
                placeholder="0"
                symbol='currency'
                min={0}
                onChange={updatePartnerSalary}
                value={query.partnerSalary}
              />
            )}

            {query.partnerEducation && (
              <InputField
                id='partner-student-loan'
                required
                labelText='Student loan (per month)'
                placeholder="0"
                symbol='currency'
                min={0}
                onChange={updatePartnerStudentLoan}
                value={query.partnerStudentLoan}
              />
            )}
          </InputTableRow>
        </InputTable>
      )}

      <InputTable
        heading={query.paidWork || query.education || query.partnerPaidWork || query.partnerEducation ? 'Other income' : 'Income'}
        buttonText='Add another income'
        onClick={(event) => {
          event.preventDefault();
          addIncome();
        }}
      >
        {query.incomeState.map((item, index) => (
          <InputTableRow
            heading={`${index+1}.`}
            handleRemove={(event) => {
              event.preventDefault();
              handleRemoveIncomeRow(index);
            }}
            key={index}
          >
            <InputField
              id={`income-name-${index}`}
              required
              labelText='Income name'
              type='text'
              dataIndex={index}
              dataName='incomeName'
              value={query.incomeState[index].incomeName}
              onChange={handleIncomeChange}
            />

            <InputField
              id={`income-value-${index}`}
              required
              labelText='Monthly amount'
              symbol='currency'
              placeholder="0"
              min={0}
              dataIndex={index}
              dataName='incomeValue'
              value={isNaN(query.incomeState[index].incomeValue) ? 0 : query.incomeState[index].incomeValue}
              onChange={handleIncomeChange}
            />
          </InputTableRow>
        ))}
      </InputTable>
    </>
  );
};

Earnings.propTypes = {
  definitions: PropTypes.array.isRequired,
};

export default Earnings;